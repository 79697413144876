import Vue from 'vue'
import VueRouter from 'vue-router'

const includPush = VueRouter.prototype.push;
const includReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
  return includReplace.call(this, location).catch(err => err)
}
import Main from "@/views/Main";
import Splash from '@/views/splash/index' //启动页

/*********************** 社区 ******************/
import Community from '@/views/community/index' //社区
const ComRecommend = () => import("@/views/community/recommend") //推荐
const ComFoucus = () => import("@/views/community/foucus") //关注
const Search = () => import("@/views/community/search") //搜索
const SearchDetails = () => import("@/views/community/search/details/index") //搜索详情
const SearchDetailsMovies = () => import("@/views/community/search/details/searchMovies") //搜索详情--影视
const SearchDetailsVideo = () => import("@/views/community/search/details/searchVideo") //搜索详情--视频
const SearchDetailsGraphices = () => import("@/views/community/search/details/searchGraphices") //搜索详情--图文
const SearchDetailsUser = () => import("@/views/community/search/details/searchUser") //搜索详情--用户
const SearchDetailsTopic = () => import("@/views/community/search/details/searchTopic") //搜索详情--话题
const UploadGraphics = () => import("@/views/community/publish/uploadGraphics") //发布图文
const UploadVIdeo = () => import("@/views/community/publish/uploadVIdeo") //发布视频
const PostDetails = () => import("@/views/community/postDetails") //帖子详情
const ReleaseRules = () => import("@/views/community/publish/releaseRules") //发布规则
const Tags = () => import("@/views/community/tags") //标签详情
const TagsVideo = () => import("@/views/community/tags/tagsVideo") //标签 -- 视频
const TagsGraphics = () => import("@/views/community/tags/tagsAll") //标签 -- 图文
const TagsMovies = () => import("@/views/community/tags/tabsMovies") //标签 -- 影视
const PopularUpMaster = () => import("@/views/community/popularUpMaster") //热门up主
const PopularWork = () => import("@/views/community/popularWork") //热门作品


const City = () => import("@/views/community/city") //城市选择
const TopicMore = () => import("@/views/community/publish/topicMore") //话题更多选择
const PicturePreview = () => import("@/views/community/picturePreview") //图片预览
const CommunityVideo = () => import("@/views/community/communityVideo") //社区视频播放

/*********************** 游戏 ******************/
const Game = () => import("@/views/game") //游戏大厅
const GamePlay = () => import("@/views/game/gamePlay") //游戏页面
const GameWallet = () => import("@/views/game/gameWallet") //游戏钱包
const GameWithdraw = () => import("@/views/game/gameWithdraw") //游戏提现
const GameRules = () => import("@/views/game/gameRules") //游戏规则

/*********************** 约炮 ******************/
const YuePao = () => import("@/views/yuepao") //约炮模块

/*********************** 影视 ******************/
const Movies = () => import("@/views/movies") //影视
const MoviesDomestic = () => import("@/views/movies/domestic") //影视--国产
const MoviesOriginal = () => import("@/views/movies/original") //影视--原创
const MoviesAv = () => import("@/views/movies/av") //影视--AV
const MoviesVarietyShow = () => import("@/views/movies/varietyShow") //影视--综艺
const MoviesCartoon = () => import("@/views/movies/cartoon") //影视--动漫
const MoviesSoftGirl = () => import("@/views/movies/softGirl") //影视--软妹
const MoviesYellow = () => import("@/views/movies/internetYellow.vue") //影视--动漫

const MoviesMore = () => import("@/views/movies/more") //影视更多
const MoviesHot = () => import("@/views/movies/more/hot.vue") //影视更多
const MoviesNew = () => import("@/views/movies/more/new.vue") //影视更多
const HorizontalVideo = () => import("@/views/movies/horizontalVideo") //横版视频详情

/*********************** 视频 ******************/
const ShortVideo = () => import("@/views/shortVideo") //视频
const Citys = () => import("@/views/shortVideo/citys") //标签详情
const CitysVideo = () => import("@/views/shortVideo/citys/cityVideo") //城市视频
const CitysGraphics = () => import("@/views/shortVideo/citys/cityGraphices") //城市图文

/*********************** 消息  ******************/
const Information = () => import("@/views/information") //消息
const AddUser = () => import("@/views/information/addUser") //添加用户
const Announcement = () => import("@/views/information/announcement") //公告
const AnnounDetails = () => import("@/views/information/announcement/announDetails") //公告详情
const MsgDetails = () => import("@/views/information/msgDetails") //消息详情
const InfoIncome = () => import("@/views/information/income") //收益详情


/*********************** 我的  ******************/
const Mine = () => import("@/views/mine") //我的
const Login = () => import("@/views/mine/login") //登录
const PersonalInfo = () => import("@/views/mine/personalInfo") //个人资料
const PrivacyPolicy = () => import("@/views/mine/privacyPolicy") //隐私政策
const PromotiionSettings = () => import("@/views/mine/promotiionSettings") //推广设置
const About = () => import("@/views/mine/about") //关于app
const TermsOfService = () => import("@/views/mine/termsOfService") //服务条款
const Introduction = () => import("@/views/mine/personalInfo/introduction") //简介编辑
const NickName = () => import("@/views/mine/personalInfo/nickName") //昵称编辑
const BindPhone = () => import("@/views/mine/personalInfo/bindPhone") //绑定手机号
const PhonePage = () => import("@/views/mine/personalInfo/phonePage") //手机号
const BindSuccess = () => import("@/views/mine/personalInfo/bindSuccess") //绑定成功
const ChangeAvatar = () => import("@/views/mine/changeAvatar") //切换头像
const ChangeBackground = () => import("@/views/mine/changeBackground") //切换背景
const ActCredentials = () => import("@/views/mine/personalInfo/actCredentials") //账号凭证
const ActRecovery = () => import("@/views/mine/personalInfo/actRecovery") //账号找回
const PhoneRecovery = () => import("@/views/mine/personalInfo/phoneRecovery") //手机号找回
const MemberCentre = () => import("@/views/mine/memberCentre") //会员中心
const MyWallet = () => import("@/views/mine/myWallet") //我的钱包
const ExchangeMember = () => import("@/views/mine/exchangeMember") //兑换会员
const Withdraw = () => import("@/views/mine/withdraw") //提现
const WithdrawDetails = () => import("@/views/mine/withdrawDetails") //提现明细
const DynamicList = () => import("@/views/mine/dynamicList") //动态
const FocusOn = () => import("@/views/mine/focusOn") //关注
const Fans = () => import("@/views/mine/fans") //粉丝
const UserHomePage = () => import("@/views/mine/userHomePage") //用户主页
const Report = () => import("@/views/mine/report") //举报
const CreationCenter = () => import("@/views/mine/creationCenter") //创作中心
const Help = () => import("@/views/mine/help") //帮助
const MoreLists = () => import("@/views/mine/creationCenter/moreLists") // 更多榜单
const WorksManage = () => import("@/views/mine/creationCenter/worksManage") // 更多榜单
const MyCertification = () => import("@/views/mine/myCertification") //我的认证
const MyPurchases = () => import("@/views/mine/myPurchases") //我的购买
const PurchasesMovieList = () => import("@/views/mine/myPurchases/movieList") //我的购买-影视
const PurchasesVideoList = () => import("@/views/mine/myPurchases/videoList") //我的购买-视频
const PurchasesPostList = () => import("@/views/mine/myPurchases/postList") //我的购买-帖子
const MyCollection = () => import("@/views/mine/myCollection") //我的收藏
const CollectionMovieList = () => import("@/views/mine/myCollection/collecMovieList") //我的收藏-影视
const CollecVideoList = () => import("@/views/mine/myCollection/collecVideoList") //我的收藏-视频
const CollecPostList = () => import("@/views/mine/myCollection/collecPostList") //我的收藏-帖子
const CollecTopic = () => import("@/views/mine/myCollection/collecTopic") //我的收藏-话题
const TaskHall = () => import("@/views/mine/taskHall") //任务大厅
const TaskDetails = () => import("@/views/mine/taskHall/taskDetails") //任务详情
const WishList = () => import("@/views/mine/wishList") //心愿工单
const QuestionDetails = () => import("@/views/mine/wishList/questionDetails") //问题详情
const IncomeCenter = () => import("@/views/mine/incomeCenter") //收益中心
const IncomeDetails = () => import("@/views/mine/incomeDetails") //收益明细
const BillDetails = () => import("@/views/mine/billDetails") //账单明细
const PromoRecord = () => import("@/views/mine/promoRecord") //推广记录
const SharePromote = () => import("@/views/mine/sharePromote") //分享推广
const HistoryRecord = () => import("@/views/mine/historyRecord") //历史记录
const HistoryVideo = () => import("@/views/mine/historyRecord/historyVideo") //历史记录 视频
const HistoryMovies = () => import("@/views/mine/historyRecord/historyMovies") //历史记录 影视
const HistoryTopic = () => import("@/views/mine/historyRecord/historyTopic") //历史记录 话题
const HistoryPost = () => import("@/views/mine/historyRecord/historyPost") //历史记录 帖子
const OfficialCommunity = () => import("@/views/mine/officialCommunity") //官方社群
const BoutiqueApp = () => import("@/views/mine/boutiqueApp") //精品应用
const Kefu = () => import("@/views/kefu") //客服
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path: '/search/details',
    name: 'SearchDetails',
    component: SearchDetails,
    redirect: '/search/details/movies',
    children: [{
        path: '/search/details/movies',
        name: 'SearchDetailsMovies',
        component: SearchDetailsMovies
      },
      {
        path: '/search/details/video',
        name: 'SearchDetailsVideo',
        component: SearchDetailsVideo
      },
      {
        path: '/search/details/graphices',
        name: 'SearchDetailsGraphices',
        component: SearchDetailsGraphices
      },
      {
        path: '/search/details/user',
        name: 'SearchDetailsUser',
        component: SearchDetailsUser
      },
      {
        path: '/search/details/topic',
        name: 'SearchDetailsTopic',
        component: SearchDetailsTopic
      },
    ]
  },
  {
    path: '/uploadGraphics',
    name: 'UploadGraphics',
    component: UploadGraphics
  },
  {
    path: '/gamePlay',
    name: 'gamePlay',
    component: GamePlay
  },
  {
    path: '/gameWallet',
    name: 'gameWallet',
    component: GameWallet
  },
  {
    path: '/gameWithdraw',
    name: 'gameWithdraw',
    component: GameWithdraw
  },
  {
    path: '/gameRules',
    name: 'gameRules',
    component: GameRules
  },
  {
    path: '/withdrawDetails',
    name: 'withdrawDetails',
    component: WithdrawDetails
  },
  {
    path: '/uploadVIdeo',
    name: 'UploadVIdeo',
    component: UploadVIdeo
  },
  {
    path: '/postDetails',
    name: 'PostDetails',
    component: PostDetails
  },
  {
    path: '/releaseRules',
    name: 'ReleaseRules',
    component: ReleaseRules
  },
  {
    path: '/tags',
    name: 'Tags',
    redirect: '/tags/video',
    component: Tags,
    children: [{
        path: '/tags/video',
        name: 'TagsVideo',
        component: TagsVideo
      },
      {
        path: '/tags/graphics',
        name: 'TagsGraphics',
        component: TagsGraphics
      },
      {
        path: '/tags/movies',
        name: 'TagsMovies',
        component: TagsMovies
      },
    ]
  },
  {
    path: '/popularUpMaster',
    name: 'PopularUpMaster',
    component: PopularUpMaster
  },
  {
    path: '/popularWork',
    name: 'PopularWork',
    component: PopularWork
  },
  {
    path: '/city',
    name: 'City',
    component: City
  },
  {
    path: '/topicMore',
    name: 'TopicMore',
    component: TopicMore
  },
  {
    path: '/picturePreview',
    name: 'PicturePreview',
    component: PicturePreview
  },
  {
    path: '/communityVideo',
    name: 'CommunityVideo',
    component: CommunityVideo
  },
  {
    path: '/movies/more',
    redirect: "/movies/more/new",
    name: 'MoviesMore',
    component: MoviesMore,
    children: [{
        path: '/movies/more/hot',
        name: 'MoviesHot',
        component: MoviesHot,
      },
      {
        path: '/movies/more/new',
        name: 'MoviesNew',
        component: MoviesNew,
      },
    ]
  },
  {
    path: '/horizontalVideo',
    name: 'HorizontalVideo',
    component: HorizontalVideo
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: Announcement,
  },
  {
    path: '/announDetails',
    name: 'AnnounDetails',
    component: AnnounDetails,
  },
  {
    path: '/msgDetails',
    name: 'MsgDetails',
    component: MsgDetails,
  },
  {
    path: '/infoIncome',
    name: 'InfoIncome',
    component: InfoIncome,
  },
  {
    path: '/addUser',
    name: 'AddUser',
    component: AddUser,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/personalInfo',
    name: 'PersonalInfo',
    component: PersonalInfo,
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '/promotiionSettings',
    name: 'promotiionSettings',
    component: PromotiionSettings,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/termsOfService',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction,
  },
  {
    path: '/nickName',
    name: 'NickName',
    component: NickName,
  },
  {
    path: '/bindPhone',
    name: 'BindPhone',
    component: BindPhone,
  },
  {
    path: '/phonePage',
    name: 'PhonePage',
    component: PhonePage,
  },
  {
    path: '/bindSuccess',
    name: 'BindSuccess',
    component: BindSuccess,
  },
  {
    path: '/changeAvatar',
    name: 'ChangeAvatar',
    component: ChangeAvatar,
  },
  {
    path: '/changeBackground',
    name: 'ChangeBackground',
    component: ChangeBackground,
  },
  {
    path: '/actCredentials',
    name: 'ActCredentials',
    component: ActCredentials,
  },
  {
    path: '/actRecovery',
    name: 'ActRecovery',
    component: ActRecovery,
  },
  {
    path: '/phoneRecovery',
    name: 'PhoneRecovery',
    component: PhoneRecovery,
  },
  {
    path: '/memberCentre',
    name: 'MemberCentre',
    component: MemberCentre,
  },
  {
    path: '/myWallet',
    name: 'MyWallet',
    component: MyWallet,
  },
  {
    path: '/exchangeMember',
    name: 'ExchangeMember',
    component: ExchangeMember,
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
  },
  {
    path: '/taskHall',
    name: 'TaskHall',
    component: TaskHall,
  },
  {
    path: '/taskDetails',
    name: 'TaskDetails',
    component: TaskDetails,
  },
  {
    path: '/dynamicList',
    name: 'DynamicList',
    component: DynamicList
  },
  {
    path: '/focusOn',
    name: 'FocusOn',
    component: FocusOn
  },
  {
    path: '/fans',
    name: 'Fans',
    component: Fans
  },
  {
    path: '/userHomePage',
    name: 'UserHomePage',
    component: UserHomePage
  },
  {
    path: '/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/creationCenter',
    name: 'CreationCenter',
    component: CreationCenter
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/moreLists',
    name: 'MoreLists',
    component: MoreLists
  },
  {
    path: '/worksManage',
    name: 'WorksManage',
    component: WorksManage
  },
  {
    path: '/myCertification',
    name: 'MyCertification',
    component: MyCertification
  },
  {
    path: '/wishList',
    name: 'WishList',
    component: WishList,
  },
  {
    path: '/questionDetails',
    name: 'QuestionDetails',
    component: QuestionDetails,
  },
  {
    path: '/incomeCenter',
    name: 'IncomeCenter',
    component: IncomeCenter,
  },
  {
    path: '/incomeDetails',
    name: 'IncomeDetails',
    component: IncomeDetails,
  },
  {
    path: '/billDetails',
    name: 'BillDetails',
    component: BillDetails,
  },
  {
    path: '/promoRecord',
    name: 'PromoRecord',
    component: PromoRecord,
  },
  {
    path: '/sharePromote',
    name: 'SharePromote',
    component: SharePromote,
  },
  {
    path: '/historyRecord',
    name: 'HistoryRecord',
    component: HistoryRecord,
    redirect: '/historyMovies',
    children: [{
        path: '/historyVideo',
        name: 'HistoryVideo',
        component: HistoryVideo,
      },
      {
        path: '/historyMovies',
        name: 'HistoryMovies',
        component: HistoryMovies,
      },
      {
        path: '/historyTopic',
        name: 'HistoryTopic',
        component: HistoryTopic,
      },
      {
        path: '/historyPost',
        name: 'HistoryPost',
        component: HistoryPost,
      },
    ]
  },
  {
    path: '/officialCommunity',
    name: 'OfficialCommunity',
    component: OfficialCommunity,
  },
  {
    path: '/boutiqueApp',
    name: 'BoutiqueApp',
    component: BoutiqueApp,
  },
  {
    path: '/kefu',
    name: 'Kefu',
    component: Kefu,
  },
  {
    path: '/myPurchases',
    name: 'MyPurchases',
    component: MyPurchases,
    redirect: "/myPurchases/movieList",
    children: [{
        path: '/myPurchases/movieList',
        name: 'PurchasesMovieList',
        component: PurchasesMovieList,
      },
      {
        path: '/myPurchases/videoList',
        name: 'PurchasesVideoList',
        component: PurchasesVideoList,
      },
      {
        path: '/myPurchases/postList',
        name: 'PurchasesPostList',
        component: PurchasesPostList,
      },
    ]
  },
  {
    path: '/citys',
    name: 'Citys',
    redirect: '/citys/video',
    component: Citys,
    children: [{
        path: '/citys/video',
        name: 'CitysVideo',
        component: CitysVideo
      },
      {
        path: '/citys/graphics',
        name: 'CitysGraphics',
        component: CitysGraphics
      },
    ]
  },
  {
    path: '/myCollection',
    name: 'MyCollection',
    component: MyCollection,
    redirect: "/myCollection/collecMovieList",
    children: [{
        path: '/myCollection/collecMovieList',
        name: 'CollectionMovieList',
        component: CollectionMovieList,
      },
      {
        path: '/myCollection/collecVideoList',
        name: 'CollecVideoList',
        component: CollecVideoList,
      },
      {
        path: '/myCollection/collecPostList',
        name: 'CollecPostList',
        component: CollecPostList,
      },
      {
        path: '/myCollection/collecTopic',
        name: 'CollecTopic',
        component: CollecTopic,
      },
    ]
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    children: [{
        path: '/community',
        name: 'Community',
        component: Community,
        redirect: "/community/recommend",
        children: [{
            path: '/community/recommend',
            name: 'ComRecommend',
            component: ComRecommend,
          },
          {
            path: '/community/shortVideo',
            name: 'ShortVideo',
            component: ShortVideo,
          },
        ]
      },
      {
        path: '/movies',
        name: 'Movies',
        component: Movies,
        redirect: '/movies/softGirl',
        children: [{
            path: '/movies/domestic',
            name: 'MoviesDomestic',
            component: MoviesDomestic,
          },
          {
            path: '/movies/original',
            name: 'MoviesOriginal',
            component: MoviesOriginal,
          },
          {
            path: '/movies/av',
            name: 'MoviesAv',
            component: MoviesAv,
          },
          {
            path: '/movies/varietyShow',
            name: 'MoviesVarietyShow',
            component: MoviesVarietyShow,
          },
          {
            path: '/movies/cartoon',
            name: 'MoviesCartoon',
            component: MoviesCartoon,
          },
          {
            path: '/movies/softGirl',
            name: 'MoviesSoftGirl',
            component: MoviesSoftGirl,
          },
          {
            path: '/movies/yellow',
            name: 'MoviesYellow',
            component: MoviesYellow,
          },
        ]
      },
      {
        path: '/game',
        name: 'Game',
        component: Game,
      },
      {
        path: '/information',
        name: 'Information',
        component: Information,
      },
      {
        path: '/mine',
        name: 'Mine',
        component: Mine,
      },
      {
        path: '/yuepao',
        name: 'yuepao',
        component: YuePao,
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router