<template>
  <van-popup v-model="show" :close-on-click-overlay="false">
    <div class="wrapper">
      <h2>温馨提示</h2>
      <div class="line"></div>
      <div class="text">
        {{ isVip ? '该分类包含大量血腥暴力视频，是否确定进入？' : '该分类需要VIP权限' }}
      </div>
      <div class="btn_box">
        <div class="cancel" @click="$emit('cancel')">取消</div>
        <div class="confirm" @click="$emit('confirm')">
          {{ isVip ? '确定' : '购买' }}
        </div>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PromptPopup',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isVip: 'isVip',
    }),
  },
};
</script>
<style lang="scss" scoped>
.van-popup {
  border-radius: 10px;
}
.wrapper {
  width: 74vw;
  color: #000000;
  border-radius: 8px;
  padding: 0 16px;
  .line {
    width: 100%;
    height: 2px;
    background: #ff678f;
  }
  h2 {
    padding: 24px 0 8px 0;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }
  .text {
    padding-top: 16px;
    padding-bottom: 20px;
    font-size: 14px;
    text-align: center;
  }
  .btn_box {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    div {
      width: 120px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #ffffff;
      border-radius: 20px;
    }
    div:nth-child(1) {
      background: #c8c9cc;
    }
    div:nth-child(2) {
      background: #ff678f;
    }
  }
}
</style>
