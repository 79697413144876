import axios from "@/utils/request";

// 登录
export function userLogin(data) {
    return axios.post(`mine/login/h5`, data);
}

// 查询用户信息
export function queryUserInfo(data) {
    return axios.get(`mine/info`, {
        params: data
    })
}

// 查询钱包信息
export function queryWallet() {
    return axios.get(`mine/wallet`)
}

//点赞接口
export function zan(data) {
    return axios.post(`thumbsUp`, data);
}

//取消点赞接口
export function cancleZan(data) {
    return axios.post(`thumbsDown`, data);
}

//关注或者取消关注接口博主
export function careOrcancle(data) {
    return axios.post(`mine/follow`, data);
}

//收藏/取消视频
/**"video"   //视频
"movie" //影视
"img"   //图文
"tag"  //话题
 */
export function careCollectVideo(data) {
    return axios.post(`mine/collect`, data);
}

//评论回复
export function commentSend(data) {
    return axios.post(`comment/send`, data);
}

// 查询评论列表
export function queryCommentList(data) {
    return axios.get(`comment/list`, {
        params: data
    })
}

// 查询2级评论
export function queryCommentListlever2(data) {
    return axios.get(`comment/info`, {
        params: data
    })
}

//转发
export function forwardVid(data) {
    return axios.post(`vid/forward`, data)
}

//修改个人资料
export function modifyPersonInfo(data) {
    return axios.post(`mine/info`, data)
}

// 发送验证码 1-绑定手机号 2-手机号登陆
export function sendCode(data) {
    return axios.post(`notification/captcha`, data);
}

// 绑定-换绑手机号
export function mobileBind(data) {
    return axios.post(`mine/mobileBind`, data);
}

// 上传凭证找回账号
export function parseQrcode(data) {
    return axios({
        url: 'mine/qrcode/extract',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

// 扫码登录
export function loginGuest(data) {
    return axios.post(`mine/login/h5/qr`, data);
}

// 举报
export function report(data) {
    return axios.post(`mine/report`, data);
}

//获取客服链接接口
export function getService(data) {
    return axios.get(`im/whiteSign`, {
        params: data
    });
}

// 获取头像、背景列表
export function getUserResource(data) {
    return axios.get(`mine/userresource/list`, {
        params: data
    });
}

// 查询新手优惠剩余时间
export function queryTiroCountdown() {
    return axios.get(`vip/tiroCountdown`)
}

// 获取推广配置
export function queryPopConfigList() {
    return axios.get(`vid/pop_config/list`)
}
