import moment from "moment";
//点赞 观看数量过滤器 例如超过一万就显示1.几万
export var watchCount = (value) => {
  let str = value || 0;
  if (value >= 10000) {
    let totleNum = (value / 10000).toFixed(1);
    str = `${parseFloat(totleNum)}W`;
  } else if (value >= 1000) {
    let totleNum = (value / 1000).toFixed(1);
    str = `${parseFloat(totleNum)}K`;
  }
  return str;
}

//点赞 观看数量过滤器 例如超过一万就显示1.几万
export var watchCountW = (value) => {
  let str = value || 0;
  if (value >= 10000) {
    let totleNum = (value / 10000).toFixed(1);
    str = `${parseFloat(totleNum)}W`;
  }
  return str;
}


export var dateago = (date) => { //评论列表里面的时间过滤器 超过一天就显示某年某月某日 没超过一天就显示几小时 几分钟前
  if (!date) return 0;
  var dateago = new Date(date);
  var dateNum = dateago.getTime();
  var separate = new Date().getTime() - dateNum; //与现在相差的时间戳
  if (separate > 86400000) { //如果时间大于一天
    return dateago.getFullYear() + '年' + (dateago.getMonth() + 1) + '月' + dateago.getDate() + '日';
  } else if (separate > 3600000) { //如果大于一个小时
    return Math.floor(separate / 3600000) + '小时前';
  } else if (separate > 60000) { //如果大于一分钟
    return Math.floor(separate / 60000) + '分钟前';
  } else {
    return '刚刚';
  }
}
export const time = (time) => {
  let date = new Date(time);
  let sdate = date.toLocaleString(undefined, {
    hour12: false,
    timeZone: "Asia/Shanghai"
  });
  return sdate;
}

export const timeYMD = (time) => {
  return moment(time).format("YYYY/MM/DD");
}
export const timeHM = (time) => {
  return moment(time).format("hh:mm");
}

export var videotime = (value) => { //视频时长过滤器
  let hour = parseInt(value / 60 / 60);
  let min = parseInt((value / 60) % 60);
  let sec = parseInt(value % 60)
  if (hour < 10) {
    hour = "0" + hour;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (sec < 10) {
    sec = "0" + sec;
  }
  return hour + ":" + min + ":" + sec;
}