import {
    Api
} from "@/utils";
import {
    queryTiroCountdown,
    queryUserInfo,
    queryWallet
} from "@/api/user";
import {
    getSessionItem,
    setSessionItem
} from "@/utils/longStorage";

const state = {
    unreadMessages: {}, // 未读消息数量
    userInfo: getSessionItem("userInfo") || {}, // 用户信息
    walletInfo: {}, // 钱包信息
    countdownSecInfo: {}, //新手卡剩余信息
    timer: "", // 倒计时
    commentData: {
        index: -1, //当前评论消息的索引
        show: false, //是否打开评论弹窗
        params: {} //参数
    },
    setTopData: {
        id: "", // 置顶id
        action: 0, // 1-置顶 2-取消置顶
        promoteStatus: 2, // 1-推广 2-未推广
        status: 0,
        publisherPopStatus: false, //是否在推广中
        promoteInfo: {
            cover: "",
            title: "",
            user: {},
        }, //1-推广 3-未推广
        popShow: false, // 弹窗状态
        type: 'video', // video-抖音 post-图文 movie-影视
    },
    shareDate: {
        show: false, //是否打开分享
        info: {} //视频信息
    },
    vipDate: {
        show: false, //是否打开vip权限弹窗
    },
    //社区推荐评论成功
    updateCommunityR: {
        isSuccess: false,
        id: ""
    },
    //社区关注评论成功
    updateCommunityF: {
        isSuccess: false,
        id: ""
    },
    //标签视频评论成功
    updateTagVideo: {
        isSuccess: false,
        id: ""
    },
    //标签图文评论成功
    updateTagGraphics: {
        isSuccess: false,
        id: ""
    },
    //短视频评论成功
    updateShortVideo: {
        isSuccess: false,
        id: ""
    },
    //社区短视频评论成功
    updateShortVideoCommunity: {
        isSuccess: false,
        id: ""
    },
    //我的收藏评论成功-图文
    updatecollecPostList: {
        isSuccess: false,
        id: ""
    },
    //城市列表评论成功-图文
    updatecollecCityList: {
        isSuccess: false,
        id: ""
    },
}
const mutations = {
    UPDATE_COMMUNITY_R: (state, {
        isSuccess,
        id
    }) => {
        state.updateCommunityR.isSuccess = isSuccess;
        state.updateCommunityR.id = id;
    },
    UPDATE_COMMUNITY_F: (state, {
        isSuccess,
        id
    }) => {
        state.updateCommunityF.isSuccess = isSuccess;
        state.updateCommunityF.id = id;
    },
    UPDATE_Graphics: (state, {
        isSuccess,
        id
    }) => {
        state.updateTagGraphics.isSuccess = isSuccess;
        state.updateTagGraphics.id = id;
    },
    UPDATE_Video: (state, {
        isSuccess,
        id
    }) => {
        state.updateTagVideo.isSuccess = isSuccess;
        state.updateTagVideo.id = id;
    },
    UPDATE_SHORT_Video: (state, {
        isSuccess,
        id
    }) => {
        state.updateShortVideo.isSuccess = isSuccess;
        state.updateShortVideo.id = id;
    },
    UPDATE_SHORT_Video_Community: (state, {
        isSuccess,
        id
    }) => {
        state.updateShortVideoCommunity.isSuccess = isSuccess;
        state.updateShortVideoCommunity.id = id;
    },
    UPDATE_Collec_Graphics: (state, {
        isSuccess,
        id
    }) => {
        state.updatecollecPostList.isSuccess = isSuccess;
        state.updatecollecPostList.id = id;
    },
    UPDATE_CITY_Graphics: (state, {
        isSuccess,
        id
    }) => {
        state.updatecollecCityList.isSuccess = isSuccess;
        state.updatecollecCityList.id = id;
    },
    // 未读消息
    SET_UNREADMSG: (state, data) => {
        state.unreadMessages = data
    },
    /*******************************/
    // 用户信息
    SET_USERINFO: (state, info) => {
        state.userInfo = info;
    },
    UPDATE_COMMENT: (state, data) => {
        state.commentData = data
    },
    // 设置置顶信息
    UPDATE_SETTOPDATA: (state, data) => {
        state.setTopData = data
    },
    // 设置置顶信息
    UPDATE_SETTOPDATA_SHOW: (state, status) => {
        state.setTopData.popShow = status
    },
    // 钱包信息
    SET_WALLET: (state, walletInfo) => {
        state.walletInfo = walletInfo;
    },
    UPDATE_SHARE: (state, data) => {
        state.shareDate = data
    },
    UPDATE_VIP_POPUPS: (state, data) => {
        state.vipDate = data
    },
    UPDATE_NEW_VIP_CARD: (state, data) => {
        clearInterval(state.timer);
        state.countdownSecInfo = data;
        // 计算时分秒数
        let hr = parseInt(state.countdownSecInfo.countdownSec / 60 / 60)
        let min = parseInt(state.countdownSecInfo.countdownSec / 60 % 60)
        let sec = parseInt(state.countdownSecInfo.countdownSec % 60)
        // 个位数前补零
        hr = hr > 9 ? hr : '0' + hr
        min = min > 9 ? min : '0' + min
        sec = sec > 9 ? sec : '0' + sec
        state.countdownSecInfo.timeStr = `${hr}:${min}:${sec}`;
        if (state.countdownSecInfo.countdownSec <= 0) return;
        state.timer = setInterval(() => {
            let countdownSec = state.countdownSecInfo.countdownSec;
            if (countdownSec <= 0) {
                clearInterval(state.timer);
                return;
            }
            // 计算时分秒数
            let hr = parseInt(countdownSec / 60 / 60)
            let min = parseInt(countdownSec / 60 % 60)
            let sec = parseInt(countdownSec % 60)
            // 个位数前补零
            hr = hr > 9 ? hr : '0' + hr
            min = min > 9 ? min : '0' + min
            sec = sec > 9 ? sec : '0' + sec
            state.countdownSecInfo.timeStr = `${hr}:${min}:${sec}`;
            state.countdownSecInfo.countdownSec--;
        }, 1000)
    }
}

const actions = {
    async getUserInfo({
        commit
    }) {
        let res = await Api(queryUserInfo);
        if (res.code === 200) {
            setSessionItem("userInfo", JSON.stringify(res.data));
            commit("SET_USERINFO", res.data);
        }
    },
    async getWallet({
        commit
    }) {
        let res = await Api(queryWallet);
        if (res.code === 200) {
            commit("SET_WALLET", res.data);
        }
    },
    async getVipTiroCountdown({
        commit
    }) {
        let res = await Api(queryTiroCountdown);
        if (res.code === 200) {
            commit("UPDATE_NEW_VIP_CARD", res.data);
        }
    },


}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}