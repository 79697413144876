<template>
  <div class="community">
    <router-link class="search" to="/search" v-show="curentRouterPath == '/community/recommend'">
      <svg-icon iconClass="search"></svg-icon>
    </router-link>

    <div class="add" @click="showPopover = true" id="add" v-show="curentRouterPath == '/community/recommend'">
      <van-popover
        v-model="showPopover"
        placement="bottom-end"
        @select="selectPopover"
        :overlay="true"
        trigger="click"
        :actions="actions"
        class="popover"
      >
        <template #reference>
          <svg-icon iconClass="add"></svg-icon>
        </template>
      </van-popover>
    </div>
    <router-link class="add" to="/search" v-show="curentRouterPath == '/community/shortVideo'">
      <svg-icon iconClass="video_search"></svg-icon>
    </router-link>

    <div :class="['my_tab', { new: curentRouterPath == '/community/recommend' }]">
      <div class="my_tab_b">
        <div :class="['tab_item', { active: curentRouterPath == '/community/recommend' }]" @click="changeTab('/community/recommend')">最新</div>
        <div :class="['tab_item', { activeVideo: curentRouterPath == '/community/shortVideo' }]" @click="changeTab('/community/shortVideo')">
          抖音
        </div>
      </div>
    </div>
    <div :class="['content', { pt40: curentRouterPath == '/community/recommend' }]">
      <keep-alive>
        <router-view :isOpenDarkWeb="isOpenDarkWeb" @open="openSuc" @lastTitle="lastTitle" ref="routerViewRef" />
      </keep-alive>
    </div>
    <KindTipsPop :show="kindTipsShow" @cancel="cancel" @confirm="confirm" />
    <AnnouncementText />
  </div>
</template>
<script>
import { Tab, Tabs, Grid, GridItem } from 'vant';
import { Popover } from 'vant';
import KindTipsPop from '@/components/kindTipsPop/index.vue';
import AnnouncementText from '@/components/AnnouncementText';
import { getMarquee, MarqueeType } from '@/utils/getConfig';
import { setSessionItem } from '@/utils/longStorage';
import { mapGetters } from 'vuex';

export default {
  name: 'community',
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popover.name]: Popover,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    KindTipsPop,
    AnnouncementText,
  },
  data() {
    return {
      activeName: '/community/recommend',
      tabs: [
        {
          path: '/community/recommend',
          name: '最新',
        },
        {
          path: '/community/shortVideo',
          name: '抖音',
        },
      ],
      showPopover: false,
      kindTipsShow: false,
      isOpenDarkWeb: false,
      sonActiveName: '',
      actions: [
        {
          text: '图文',
          icon: require('@/assets/png/graphics.png'),
          type: 'graphics',
        },
        {
          text: '视频',
          icon: require('@/assets/png/video.png'),
          type: 'video',
        },
      ],
    };
  },
  async created() {
    this.activeName = this.$route.path;
    this.marqueeText = getMarquee(MarqueeType.community);
  },
  computed: {
    ...mapGetters({
      isVip: 'isVip',
    }),
    curentRouterPath() {
      return this.$route.path;
    },
  },
  methods: {
    changeTab(name) {
      this.activeName = name;
      setSessionItem('communityPath', name);
      this.$router.replace({ path: name });
    },
    selectPopover(action) {
      if (action.type === 'graphics') {
        this.$router.push('/uploadGraphics');
        return;
      }
      if (action.type === 'video') {
        this.$router.push('/uploadVIdeo');
        return;
      }
    },
    openSuc() {
      this.kindTipsShow = true;
    },
    lastTitle(title) {
      if (title) {
        this.sonActiveName = title;
      }
    },
    cancel() {
      this.kindTipsShow = false;
      this.$refs.routerViewRef.useActiveName(this.sonActiveName);
    },
    confirm() {
      if (this.isVip) {
        this.kindTipsShow = false;
        this.$refs.routerViewRef.refreshInfo();
        return;
      }
      this.kindTipsShow = false;
      setTimeout(() => {
        this.$router.push('/memberCentre?t=vip');
        this.$refs.routerViewRef.useActiveName(this.sonActiveName);
      }, 0);
    },
  },
  activated() {
    this.showPopover = false;
  },
};
</script>
<style lang="scss" scoped>
.marquee_box {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  padding: 0 12px;
  border-radius: 18px;
  z-index: 101;
  .left_icon {
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 21px;
    }
  }
  .right_icon {
    display: flex;
    align-items: center;
    padding-left: 8px;
    svg {
      font-size: 24px;
    }
  }
  /deep/ .van-notice-bar {
    border-radius: 18px;
    height: 36px;
  }
}

.my_vant_tabs,
/deep/ .van-tabs__content,
/deep/ .van-tab__pane {
  height: 100%;
}
/deep/ .van-tabs__wrap {
  border-bottom: 1px solid #f4f4f4;
}
.my_vant_tabs {
  /deep/ .van-tab__text {
    font-size: 20px;
  }
}

.community {
  position: relative;
  height: 100%;
  overflow: hidden;
  .search {
    position: absolute;
    z-index: 1;
    left: 16px;
    height: 44px;
    display: flex;
    align-items: center;
    z-index: 101;

    svg {
      font-size: 24px;
    }
  }
  .content {
    height: 100%;
    box-sizing: border-box;
  }
  .pt40 {
    padding-top: 40px;
  }
  .new {
    background-color: #ffffff;
    border-bottom: 0.7px solid #e0e0e0;
  }
  .my_tab {
    position: absolute;
    top: 0;
    height: 44px;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #abaaac;

    .search_box {
      position: absolute;
      right: 16px;

      .search {
        > svg {
          font-size: 18px;
        }
      }
    }

    .my_tab_b {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 160px;

      .tab_item {
        padding: 0 18px;
        font-size: 18px;
        position: relative;
        color: #888888;
      }

      .active {
        color: #333333;
        font-weight: 300;
      }
      .activeVideo {
        color: #ffffff;
        font-weight: 300;
      }
    }
  }

  .add {
    position: absolute;
    z-index: 1;
    right: 16px;
    height: 44px;
    display: flex;
    align-items: center;
    z-index: 101;

    svg {
      font-size: 24px;
    }
  }

  /deep/ .van-tabs__nav {
    justify-content: center;
  }

  /deep/ .van-tab {
    font-size: 16px;
    flex: unset;
    padding: 0 20px;
  }

  /deep/ .van-tabs__line {
    bottom: 18px;
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background: linear-gradient($pumpkinOrange, $brownishOrange);
  }

  .popover {
    /deep/ .van-popover__action-text {
      font-size: 18px;
    }
  }
}
</style>
