import axios from "@/utils/request";

//获取帖子列表
export function queryPostList(data) {
    return axios.get(`vid/news/list`, {
        params: data
    })
}
export function queryHotList(data) {
    return axios.get(`vid/community/hot/list`, {
        params: data
    })
}

//获取帖子列表
export function queryCommunityList(data) {
    return axios.get(`vid/community/list`, {
        params: data
    })
}

//搜索
/**
 *
 * @param  videoType
 *  SP string = "SP"
 *  COVER string = "COVER" //COVER 图集帖子
 *  MOVIE string = "MOVIE"   //影视
 * @param  keyword 关键字
 * @returns {Promise<AxiosResponse<any>>}
 */

export function search(data) {
    return axios.get('vid/search', {
        params: data
    })
}

//搜索 标签或者用户
/**
 * @param realm
 * tag  标签  user用户
 * @param  keyword 关键字
 * @returns {Promise<AxiosResponse<any>>}
 */
export function searchTagorUser(data) {
    return axios.post('search/list', data)
}


//猜你喜欢
export function queryRecommendList(data) {
    return axios.get('recommend/user/list', {
        params: data
    })
}

//获取热门推荐
export function queryHotSearchList(data) {
    return axios.get('search/hotVid/list', {
        params: data
    })
}
export function queryHotTagList(data) {
    return axios.get('tag/list', {
        params: data
    })
}

//获取标签推荐
export function getTagList(data) {
    return axios.get('tag/v2/list', {
        params: data
    })
}

//提交帖子图片、视频
/**
 *
 * @param
    /// 用户提交作品,提交视频和图片
 /// [title]
 /// [newsType] 上传类型 COVER-图集 SP-视频
 /// [tags] 视频标签数组
 /// [playTime] 视频时长
 /// [cover] 封面
 /// [coverThumb] 封面小图
 /// [seriesCover]图集图片地址
 /// [via] 视频来源
 /// [coins] 观看金币
 /// [size] 文件大小
 /// [mimeType] 文件类型
 /// [actor] 演员名字
 /// [sourceURL]资源url 为SP时 必传
 /// [sourceID]上��视频成功后 返回���ID 为SP时必传
 /// [filename] 文件名
 /// [resolution] 分辨率
 /// [ratio] 视频比例
 /// [md5] 文件摘要
 /// [freeTime] 免费观影时长
 /// [location] 地理位置信息
 * @returns
 */
export function publishPost(data) {
    return axios.post("vid/submit", data)
}

//关注列表
export function followList(data) {
    return axios.get("follow/dynamics/list", {
        params: data
    })
}

//获取话题
export function queryTagList(data) {
    return axios.get("tag/vid/list", {
        params: data
    })
}

// 获取话题详情
export function queryTagInfo(data) {
    return axios.get("tag/info", {
        params: data
    })
}

// 查询推荐UP列表
export function queryUpRecom(data) {
    return axios.get("recommend/user/rand/list", {
        params: data
    })
}

// 获取举牌示例图配置
export function queryExampleConfigList(data) {
    return axios.get(`vid/example_config/list`, {
        params: data
    })
}