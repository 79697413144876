<template>
  <div class="main">
    <keep-alive :include="include">
      <router-view />
    </keep-alive>
    <TabBar />
    <div v-show="isShowGiftPack">
      <div class="giftPack" v-if="countdownSecInfo.countdownSec" @click="$router.push('/memberCentre?t=vip')">
        <div class="time">{{ countdownSecInfo.timeStr }}</div>
      </div>
    </div>
    <DraggableAdv :advList="advList" v-show="advList.length && !countdownSecInfo.countdownSec && isShowGiftPack"></DraggableAdv>
  </div>
</template>
<script>
import TabBar from '@/components/Tabbar';
import DraggableAdv from '@/components/DraggableAdv';
import { mapState } from 'vuex';
import { AdType, getAdItem } from '@/utils/getConfig';
export default {
  name: 'Main',
  components: {
    TabBar,
    DraggableAdv,
  },
  data() {
    return {
      include: ['movies', 'community'],
      advList: [],
    };
  },
  created() {
    this.advList = getAdItem(AdType.SUSPEND_ADV);
  },
  computed: {
    ...mapState({
      countdownSecInfo: (state) => state.user?.countdownSecInfo,
    }),
    isShowGiftPack: function () {
      let path = this.$route.path;
      if (path.indexOf('movies') > 0 || path == '/community/recommend') {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: calc(100% - 60px);
  //overflow-y: auto;
  position: relative;
  .giftPack {
    position: absolute;
    bottom: 50px;
    right: 20.6px;
    width: 104.8px;
    height: 92.4px;
    background: url('../assets/png/newGiftBag.png');
    background-size: cover;
    z-index: 11;
    .time {
      width: 100%;
      position: absolute;
      bottom: -3px;
      transform: translateY(-50%);
      color: #f61940;
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      padding-left: 3.5px;
    }
  }
}
</style>
