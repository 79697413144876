import store from '@/store/index';

const getters = {
  //接口api
  baseUrl: (state) => {
    return state.app?.baseUrl || '';
  },
  //全局loading
  globalLoading: (state) => {
    return state.app?.globalLoading || false;
  },
  //用户UID
  UID: (state) => {
    return state.app?.UID || '';
  },
  //是否vip
  isVip: (state) => {
    let userInfo = state.user?.userInfo;
    if (typeof userInfo == 'string') {
      userInfo = JSON.parse(state.user.userInfo);
    }
    return (userInfo.isVip && userInfo?.vipLevel > 0) || false;
  },
  // 置顶弹窗信息
  setTopData: (state) => {
    return state.user.setTopData;
  },

  //评论弹窗信息
  commentData: (state) => {
    return state.user.commentData;
  },
  //分享弹窗信息
  shareDate: (state) => {
    return state.user.shareDate;
  },
  //vip权限弹窗信息
  vipDate: (state) => {
    return state.user.vipDate;
  },
  //发布已选标签
  tags: (state) => {
    return state.commnity.tags;
  },
  //缓存组件列表
  keepAliveList: (state) => {
    return state.app.keepAliveList;
  },
  //用户信息
  userInfo: (state) => {
    let userInfo = state.user?.userInfo;
    if (typeof userInfo == 'string') {
      userInfo = JSON.parse(state.user.userInfo);
    }
    return userInfo;
  },
  //未读消息数量
  unreadMessages: (state) => {
    return state.user.unreadMessages;
  },
  //配置信息
  appConfig: (state) => {
    let appConfig = state.app?.appConfig;
    if (typeof appConfig == 'string') {
      appConfig = JSON.parse(state.app.appConfig);
    }
    return appConfig;
  },
  //钱包信息
  walletInfo: (state) => {
    return state.user?.walletInfo;
  },
  //热门视频
  videoObj: (state) => {
    return state.video.videoObj;
  },
  //imgapi
  imgApi: (state) => {
    let appConfig = state.app?.appConfig;
    if (typeof appConfig === 'string') {
      appConfig = JSON.parse(state.app?.appConfig);
    }
    let imgDomainList = appConfig?.sourceList?.filter((item) => item.type === 'IMAGE');
    if (imgDomainList.length > 0) {
      return imgDomainList[0]?.domain[0]?.url;
    }
    return '';
  },
  //视频api
  videoApi: (state) => {
    let appConfig = state.app?.appConfig;
    if (typeof appConfig === 'string') {
      appConfig = JSON.parse(state.app?.appConfig);
    }
    let imgDomainList = appConfig?.sourceList?.filter((item) => item.type === 'VID');
    if (imgDomainList.length > 0) {
      return imgDomainList[0]?.domain[0]?.url;
    }
    return '';
  },
  //当前播放的视频
  currentVideoObj: (state) => {
    return state.video.currentVideoObj;
  },
  //横屏广告列表
  adsBannerList: (state) => {
    if (state.video.adsBannerList.list.length < 1) {
      let appConfig = getters.appConfig(state);
      if (appConfig && appConfig['adsInfoList'].length > 0) {
        let list = [];
        for (let i = 0; i < appConfig['adsInfoList'].length; i++) {
          if (appConfig['adsInfoList'][i]['position'] == 10) {
            list.push(appConfig['adsInfoList'][i]);
          }
        }
        if (list.length > 0) {
          store.commit('video/SET_ADS_BANNER_LIST', list);
        }
      }
    }
    return state.video.adsBannerList;
  },
  //竖屏广告列表
  adsVerticalList: (state) => {
    if (state.video.adsVerticalList.list.length < 1) {
      let appConfig = getters.appConfig(state);
      if (appConfig && appConfig['adsInfoList'].length > 0) {
        let list = [];
        for (let i = 0; i < appConfig['adsInfoList'].length; i++) {
          if (appConfig['adsInfoList'][i]['position'] == 11) {
            list.push(appConfig['adsInfoList'][i]);
          }
        }
        if (list.length > 0) {
          store.commit('video/SET_ADS_VERTICAL_LIST', list);
        }
      }
    }
    console.log(state.video.adsVerticalList);
    return state.video.adsVerticalList;
  },
  //社区广告列表
  adsCommunityList: (state) => {
    if (state.video.adsCommunityList.list.length < 1) {
      let appConfig = getters.appConfig(state);
      if (appConfig && appConfig['adsInfoList'].length > 0) {
        let list = [];
        for (let i = 0; i < appConfig['adsInfoList'].length; i++) {
          if (appConfig['adsInfoList'][i]['position'] == 12) {
            list.push(appConfig['adsInfoList'][i]);
          }
        }
        if (list.length > 0) {
          store.commit('video/SET_ADS_COMMUNITY_LIST', list);
        }
      }
    }
    return state.video.adsCommunityList;
  },
  //专栏广告列表
  adsColumnList: (state) => {
    if (state.video.adsColumnList.list.length < 1) {
      let appConfig = getters.appConfig(state);
      if (appConfig && appConfig['adsInfoList'].length > 0) {
        let list = [];
        for (let i = 0; i < appConfig['adsInfoList'].length; i++) {
          if (appConfig['adsInfoList'][i]['position'] == 13) {
            list.push(appConfig['adsInfoList'][i]);
          }
        }
        if (list.length > 0) {
          store.commit('video/SET_ADS_COLUMN_LIST', list);
        }
      }
    }
    return state.video.adsColumnList;
  },
};

export default getters;
