<template>
  <div id="app" class="pcm-app" @click.prevent>
    <keep-alive :include="keepAliveList">
      <router-view v-if="isRouterAlive" />
    </keep-alive>
    <!-- 评论弹窗-->
    <PopComment />
    <!-- 全屏loading-->
    <GlobalLoading v-if="globalLoading" />
    <!-- 分享弹窗-->
    <Share v-if="shareDate.show" />
    <!-- 需要会员权限-->
    <VipBuyPer v-if="vipDate.show" />
    <!-- 温馨提示弹窗 -->
    <PromptPopup v-model="promptPopupShow" :content="content" @onClose="closePromptPopup" />
  </div>
</template>
<script>
import PopComment from '@/components/PopComment';
import GlobalLoading from '@/components/GlobalLoading';
import { mapGetters } from 'vuex';
import Share from '@/components/Share';
import VipBuyPer from '@/components/VipBuyPer';
import inobounce from '@/assets/js/inobounce.min';
import PromptPopup from '@/components/PromptPopup';
import { Dialog } from 'vant';
import { getDevType } from '@/utils';
import { dataAdd } from '@/api/app';

export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      keepPath: {
        '/community/recommend': {
          className: 'ComRecommend',
        },
        '/community/foucus': {
          className: 'my-refresh',
        },
        '/tags/video': {
          className: 'my-refresh',
        },
        '/tags/graphics': {
          className: 'my-refresh',
        },
        '/tags/movies': {
          className: 'my-refresh',
        },
        '/citys/video': {
          className: 'my-refresh',
        },
        '/citys/graphics': {
          className: 'my-refresh',
        },
        '/search/details/movies': {
          className: 'my-refresh',
        },
        '/search/details/video': {
          className: 'my-refresh',
        },
        '/search/details/graphices': {
          className: 'my-refresh',
        },
        '/search/details/user': {
          className: 'my-refresh',
        },
        '/search/details/topic': {
          className: 'my-refresh',
        },
        '/movies/more/new': {
          className: 'my-refresh',
        },
        '/movies/more/hot': {
          className: 'my-refresh',
        },
        '/announcement': {
          className: 'my-refresh',
        },
        '/movies/domestic': {
          className: 'domesticName',
        },
        '/movies/original': {
          className: 'originalName',
        },
        '/movies/av': {
          className: 'avName',
        },
        '/movies/varietyShow': {
          className: 'varietyShowName',
        },
        '/movies/cartoon': {
          className: 'cartoonName',
        },
        '/movies/yellow': {
          className: 'yellowName',
        },
        '/movies/softGirl': {
          className: 'softName',
        },
        '/historyMovies': {
          className: 'my-refresh',
        },
        '/historyVideo': {
          className: 'my-refresh',
        },
        '/wishList': {
          className: 'my-refresh',
        },
        '/myCollection/collecMovieList': {
          className: 'my-refresh',
        },
        '/myCollection/collecVideoList': {
          className: 'my-refresh',
        },
        '/myCollection/collecPostList': {
          className: 'my-refresh',
        },
        '/myCollection/collecTopic': {
          className: 'my-refresh',
        },
        '/myPurchases/movieList': {
          className: 'my-refresh',
        },
        '/myPurchases/videoList': {
          className: 'my-refresh',
        },
        '/myPurchases/postList': {
          className: 'my-refresh',
        },
        '/worksManage': {
          className: 'my-refresh',
        },
        '/userHomePage': {
          className: 'userHomePage',
        },
        '/search': {
          className: 'content',
        },
      },
      promptPopupShow: false,
      content: '',
    };
  },
  components: {
    PopComment,
    GlobalLoading,
    Share,
    VipBuyPer,
    PromptPopup,
  },
  computed: {
    ...mapGetters({
      keepAliveList: 'keepAliveList',
      globalLoading: 'globalLoading',
      shareDate: 'shareDate',
      vipDate: 'vipDate',
    }),
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    closePromptPopup() {
      this.promptPopupShow = false;
    },
  },
  mounted() {
    //google 不支持此方法，加了后页面所以滚动将被禁止
    if (getDevType() == 'ios' && /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
      inobounce.enable();
    }
    // 检测断网
    window.addEventListener('offline', () => {
      Dialog.alert({
        title: '提示',
        message: '网络已断开',
        confirmButtonText: '我已知晓',
        theme: 'round-button',
      });
    });
    // 检测网络已连接
    window.addEventListener('online', () => {
      Dialog.alert({
        title: '提示',
        message: '网络已连接',
        confirmButtonText: '我已知晓',
        theme: 'round-button',
      });
    });

    // 弹窗
    let _this = this;
    this.$bus.$on('promptPopupEvent', function (content) {
      _this.content = content;
      _this.promptPopupShow = true;
    });

    //数据埋点
    this.$bus.$on('dataBuriedEvent', function (data) {
      dataAdd(data);
    });
  },
  watch: {
    $route(to, from) {
      //保存
      if (this.keepPath[from.path]) {
        let className = this.keepPath[from.path]?.className;
        // 查找到className才储存
        if (className) {
          let domArr = document.getElementsByClassName(className);
          let domIndex = domArr.length - 1;
          let dom = domArr[domIndex];
          let scrollTop = dom?.scrollTop;
          if (dom) {
            from.meta.scrollTop = scrollTop;
          }
        }
      }
      //  设置
      if (this.keepPath[to.path]) {
        let className = this.keepPath[to.path]?.className;
        if (className) {
          this.$nextTick(() => {
            let domArr = document.getElementsByClassName(className);
            let domIndex = domArr.length - 1;
            let dom = domArr[domIndex];
            let scrollTop = to.meta?.scrollTop;
            if (dom) {
              dom.scrollTop = scrollTop;
            }
          });
        }
      }
    },
  },
  beforeDestroy() {
    inobounce.disable();
  },
};
</script>
<style lang="scss">
#app {
  font-size: 14px;
  height: 100%;
  background: $bg;
  position: relative;
  color: #333333;
}

.pcm-app {
  width: 100%;
  max-width: 540px;
  margin: auto;
  position: relative;
  background: $bg;
  height: 100%;
  min-height: 100%;
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .van-tabbar--fixed {
    position: fixed;
    bottom: 0;
    left: auto;
    max-width: 540px;
  }
}

/* 左滑进左滑出 右滑进 右滑出 */
.slide-l-enter-active,
.slide-l-leave-active {
  transition: all 0.3s linear;
  transform: translateX(0);
}

.slide-l-enter,
.slide-l-leave {
  transform: translateX(100%);
}

.slide-l-leave-to {
  transform: translateX(100%);
}

.slide-r-enter-active,
.slide-r-leave-active {
  transition: all 0.3s linear;
  transform: translateX(0);
}

.slide-r-enter,
.slide-r-leave {
  transform: translateX(-100%);
}

.slide-r-leave-to {
  transform: translateX(-100%);
}

/* 路由切换左滑进左滑出 右滑进 右滑出 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.5s;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
