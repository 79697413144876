<template>
  <div class="adv_swiper">
    <div class="adv-container" v-if="advList.length > 0">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
        <van-swipe-item v-for="item in advList" :key="item.id" :style="{ height: height }" class="adv-swiper-slide" @click="goRoutr(item)">
          <ImgDecypt :src="item.cover" />
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>
<script>
import ImgDecypt from '@/components/ImgDecypt/index.vue';
import { jumpAdv } from '@/utils/getConfig';
export default {
  components: {
    ImgDecypt,
  },
  props: {
    advList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    height: {
      type: String,
      default: '183px',
    },
  },
  methods: {
    goRoutr(item) {
      jumpAdv(item);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-swipe__indicators {
  right: 10%;
  left: auto;
}
.adv_swiper {
  overflow: hidden;
  .adv-container {
    width: 100%;
    overflow: hidden;
    .adv-swiper-slide {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
</style>
