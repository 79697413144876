import router from "./router";
import store from "./store"
import {
  getSessionItem
} from "@/utils/longStorage";
let lastVid = "";
let lastUid = "";
let lastTagid = ""
//全局路由导航
router.beforeEach((to, from, next) => {
  const appConfigStr = getSessionItem("appConfig");
  const baseUrl = getSessionItem("baseUrl");
  if ((!appConfigStr || appConfigStr.length == 0 || !baseUrl || baseUrl.length == 0) && to.path != "/") {
    router.push({
      path: '/'
    })
    return;
  }
  //缓存组件
  let keepAliveMap = new Map();
  keepAliveMap.set('movies', 'movies')
  keepAliveMap.set('popularWork', 'popularWork')
  keepAliveMap.set('uploadGraphics', 'uploadGraphics')
  keepAliveMap.set('uploadVIdeo', 'uploadVIdeo')
  keepAliveMap.set('ComFoucus', 'ComFoucus')
  keepAliveMap.set('Main', 'Main')
  keepAliveMap.set('search', 'search')
  keepAliveMap.set('announcement', 'announcement')
  keepAliveMap.set('SearchDetails', 'SearchDetails')
  keepAliveMap.set('tags', 'tags')
  keepAliveMap.set('citys', 'citys')
  keepAliveMap.set('moviesMore', 'moviesMore')
  keepAliveMap.set('HistoryRecord', 'HistoryRecord')
  keepAliveMap.set('addUser', 'addUser')
  keepAliveMap.set('wishList', 'wishList')
  keepAliveMap.set('myCollection', 'myCollection')
  keepAliveMap.set('myPurchases', 'myPurchases')
  keepAliveMap.set('UserHomePage', 'UserHomePage')
  keepAliveMap.set('PicturePreview', 'PicturePreview')
  keepAliveMap.set('worksManages', 'worksManages')
  keepAliveMap.set('popularUpMaster', 'popularUpMaster')
  keepAliveMap.set('communityVideo', 'communityVideo')
  keepAliveMap.set('memberCentre', 'memberCentre')
  //发布图文页
  if (from.path == '/uploadGraphics' && to.path != "/topicMore" && to.path != "/city" && to.path != '/releaseRules') {
    keepAliveMap.delete('uploadGraphics');
  }
  //发布视频页
  if (from.path == '/uploadVIdeo' && to.path != "/topicMore" && to.path != "/city" && to.path != '/releaseRules') {
    keepAliveMap.delete('uploadVIdeo');
  }
  // 游戏
  if (to.path == '/game') {
    keepAliveMap.delete('Main')
  }
  //搜索页面
  if (from.path == '/search' && to.path != '/search/details/movies' && to.path != '/search/details/video' && to.path != '/search/details/graphices' && to.path != '/search/details/user' && to.path != '/search/details/topic' && to.path != '/communityVideo' && to.path != '/userHomePage') {
    keepAliveMap.delete('search');
  }
  //搜索详情页
  if (from.path === '/search') {
    keepAliveMap.delete('SearchDetails');
  }
  //标签详情页
  if ((from.path === '/tags/video' || from.path === '/tags/graphics' || '/tags/movies') && to.path != '/tags/video' && to.path != '/tags/graphics' && to.path != '/tags/movies' && to.path != '/communityVideo' && to.path != '/picturePreview' && to.path != '/horizontalVideo' && to.path != '/userHomePage') {
    keepAliveMap.delete('tags');
  }
  //标签页跳标签页
  if ((from.path == '/tags/video' || from.path == '/tags/graphics') && (to.query.fromPath == '/tags/graphics' || to.query.fromPath == '/tags/video') && from.path != '/communityVideo' && from.path != '/picturePreview') {
    keepAliveMap.delete('tags');
  }
  if ((to.path == '/tags/video' || to.path == '/tags/movies') && from.path == '/horizontalVideo' && !from.query.fromPath) {
    keepAliveMap.delete('tags');
  }
  //标签页跳播放页调标签页
  if ((to.path == '/tags/video' || to.path == '/tags/movies') && from.query.fromPath == 'tags') {
    let tagId = getSessionItem('tagId');
    if (tagId) {
      keepAliveMap.delete('tags');
    }
  }
  if ((to.path == '/tags/video' || to.path == '/tags/movies' || to.path == '/tags/graphics') && from.query.fromRoutePath != 'TagsVideo') {
    if (lastTagid != to.query.id && lastTagid != "") {
      keepAliveMap.delete('tags');
    }
    lastTagid = to.query.id;
  }
  //城市详情页
  if ((from.path === '/citys/graphics' || from.path === '/citys/video') && to.path != '/citys/graphics' && to.path != '/citys/video' && to.path != '/communityVideo' && to.path != '/picturePreview' && to.path != '/userHomePage') {
    keepAliveMap.delete('citys');
  }
  //影视详情页
  if ((from.path == '/movies/more/new' || from.path == '/movies/more/hot') && to.path != '/horizontalVideo') {
    keepAliveMap.delete('moviesMore');
  }
  //公告详情
  if (from.path == '/announcement' && to.path != '/announDetails') {
    keepAliveMap.delete('announcement');
  }
  //历史详情
  if (from.path == '/historyMovies' && to.path != '/horizontalVideo' && to.path != '/historyVideo') {
    keepAliveMap.delete('HistoryRecord');
  }
  if (from.path == '/historyVideo' && to.path != '/communityVideo' && to.path != '/historyMovies') {
    keepAliveMap.delete('HistoryRecord');
  }
  if (from.path == '/HistoryPost' && to.path != '/communityVideo' && to.path != '/historyMovies') {
    keepAliveMap.delete('HistoryRecord');
  }
  // 添加用户
  if (from.path == '/addUser' && to.path != '/userHomePage') {
    keepAliveMap.delete('addUser');
  }
  // 心愿工单
  if (from.path == '/wishList' && to.path != '/questionDetails') {
    keepAliveMap.delete('wishList');
  }
  // 我的收藏
  if (to.path == '/mine') {
    keepAliveMap.delete('myCollection');
  }
  // 我的购买
  if (to.path == '/mine') {
    keepAliveMap.delete('myPurchases');
  }
  // 用户主页
  if (to.path != '/communityVideo' && to.path != "/promotiionSettings" && to.path != "/picturePreview" && to.path != "/postDetails" && to.path != "/tags/video" && to.path != '/userHomePage' || (to.path == '/userHomePage' && to.query.from)) {
    keepAliveMap.delete('UserHomePage');
  }
  if (to.path == '/userHomePage') {
    if (lastUid != to.query.uid && lastUid != '') {
      keepAliveMap.delete('UserHomePage');
    }
    lastUid = to.query.uid;
  }
  //图片预览
  if (from.path == '/picturePreview' && to.path != '/myWallet' && to.path != '/memberCentre' && to.path != '/sharePromote') {
    keepAliveMap.delete('PicturePreview');
  }
  //作品管理
  if (from.path == '/worksManage' && to.path != '/postDetails' && to.path != '/communityVideo') {
    keepAliveMap.delete('worksManages');
  }
  //热门up主
  if (from.path == '/popularUpMaster' && to.path != '/userHomePage') {
    keepAliveMap.delete('popularUpMaster');
  }
  //热门作品
  if (from.path == '/popularWork' && to.path != '/communityVideo') {
    keepAliveMap.delete('popularWork');
  }
  //会员中心
  if (from.path == '/memberCentre' && to.path != '/mine') {
    keepAliveMap.delete('memberCentre');
  }

  //视频详情
  if (from.path == '/communityVideo' && to.path != '/userHomePage' && to.path != '/myWallet' && to.path != '/memberCentre' && to.path != '/tags/video') {
    keepAliveMap.delete('communityVideo');
  }
  if (to.path == '/communityVideo') {
    if (lastVid != to.query.vid && lastVid != '') {
      keepAliveMap.delete('communityVideo');
    }
    lastVid = to.query.vid;
  }
  let keepAliveList = [...keepAliveMap.values()]
  store.commit('app/SET_KEEP', keepAliveList);

  if (to.matched.length === 0) { //如果未能匹配到路由 则跳转到首页
    next('/')
  } else {
    next();
  }
})