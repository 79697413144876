const state = {
    //热门视频信息
    videoObj: {
        index: 0,
        list: []
    },
    //关注视频信息
    videoObjCar: {
        index: 0,
        list: []
    },
    currentVideoObj: {},
    //横屏广告
    adsBannerList: {
        index: 0,
        list: []
    },
    //竖屏广告
    adsVerticalList: {
        index: 0,
        list: []
    },
    //社区广告
    adsCommunityList: {
        index: 0,
        list: []
    },
    //专栏广告
    adsColumnList: {
        index: 0,
        list: []
    }
}
const mutations = {
    /**------------------------------------------------短视频---------------------------------------------*/
    //保存视频信息列表
    SET_VIDEOLIST: (state, {
        list,
    }) => {
        state.videoObj.list = list
    },
    //修改当前播放视频索引
    UPDATE_VIDEOLIST_INDEX: (state, {
        index,
    }) => {
        state.videoObj.index = index;

    },
    //从列表头开始删除数量
    DELETE_VIDEOLIST: (state, {
        index,
    }) => {
        state.videoObj.list.splice(0, index)

    },
    // 修改指定索引的视频信息
    UPDATE_INDEX_VIDEOINFO: (state, {
        videoInfo,
        index,
    }) => {
        state.videoObj.list[index] = videoInfo;

    },
    //更改当前关注博主的所有视频设置为关注
    UPDATE_ALL_FOCUS: (state, uid) => {
        let list = state.videoObj?.list;
        for (let i = 0; i < list.length; i++) {
            if (list[i].publisher.uid == uid) {
                list[i].publisher.hasFollowed = true;
            }
        }
    },
    SET_CURRENT_VIDEO_OBJ: (state, videoObj) => {
        state.currentVideoObj = videoObj;
    },
    //设置横屏广告
    SET_ADS_BANNER_LIST: (state, adsBannerList) => {
        state.adsBannerList.list = adsBannerList;
    },
    //更新横屏广告索引
    UPDATE_ADS_BANNER_LIST_INDEX: (state, index) => {
        if (state.adsBannerList.index + 1 > state.adsBannerList.list.length - 1) {
            state.adsBannerList.index = 0;
        } else {
            state.adsBannerList.index += 1;
        }
    },
    //设置竖屏广告
    SET_ADS_VERTICAL_LIST: (state, adsVerticalList) => {
        state.adsVerticalList.list = adsVerticalList;
    },
    //更竖横屏广告索引
    UPDATE_ADS_VERTICAL_LIST_INDEX: (state, index) => {
        if (state.adsVerticalList.index + 1 > state.adsVerticalList.list.length - 1) {
            state.adsVerticalList.index = 0;
        } else {
            state.adsVerticalList.index += 1;
        }
    },
    //设置社区广告
    SET_ADS_COMMUNITY_LIST: (state, adsCommunityList) => {
        state.adsCommunityList.list = adsCommunityList;
    },
    //设置专栏广告
    SET_ADS_COLUMN_LIST: (state, adsColumnList) => {
        state.adsColumnList.list = adsColumnList;
    }
}



export default {
    namespaced: true,
    state,
    mutations,
}