<template>
  <van-tabbar
    v-model="active"
    active-color="#e9e9e9"
    inactive-color="#727272"
    class="tabbar-warp"
    :safe-area-inset-bottom="true"
    @change="noRedMessage"
  >
    <van-tabbar-item @click="onMovies" :icon="active == 'Movies' ? homeIconActive : homeIcon" name="Movies">
      <span :style="{ color: active == 'Movies' ? '#fc4da0' : '#868b9b' }">推荐</span>
    </van-tabbar-item>
    <van-tabbar-item @click="onCommunity" @dblclick.native="refreshPage" name="Community" :icon="active == 'Community' ? communityActive : community">
      <span
        :style="{
          color: active == 'Community' ? '#ff678f' : '#868b9b',
        }"
      >
        社区
      </span>
    </van-tabbar-item>
    <van-tabbar-item to="/yuepao" :icon="active == 'Yuepao' ? gameActive : gameIcon" name="yuepao" v-if="$store.state.app.appConfig.louFengH5">
      <span :style="{ color: active == 'Yuepao' ? '#e787ff' : '#868b9b' }">娱乐</span>
    </van-tabbar-item>
    <van-tabbar-item
      :icon="active == 'Information' ? msgIconActive : msgIcon"
      to="/information"
      name="Information"
      :dot="unreadMessages.msgCount + unreadMessages.dtCount > 0"
    >
      <span
        :style="{
          color: active == 'Information' ? '#ff9b60' : '#868b9b',
        }"
      >
        消息
      </span>
    </van-tabbar-item>

    <!-- <van-tabbar-item
      :icon="active == 'Yuepao' ? ypIconActive : ypIcon"
      to="/yuepao"
      name="yuepao"
      v-if="$store.state.app.appConfig.louFengH5"
      >娱乐
    </van-tabbar-item> -->

    <van-tabbar-item :icon="active == 'Mine' ? mineIconActive : mineIcon" to="/mine" name="Mine">
      <span :style="{ color: active == 'Mine' ? '#5099f5' : '#868b9b' }">我的</span>
    </van-tabbar-item>
  </van-tabbar>
</template>
<script>
import { mapGetters } from 'vuex';
import { noRedMessage } from '@/api/app';
import { Tabbar, TabbarItem } from 'vant';
import { firstUpperCase } from '@/utils';
import { getSessionItem } from '@/utils/longStorage';

export default {
  name: 'ShopCats',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  computed: {
    ...mapGetters({
      unreadMessages: 'unreadMessages',
      appConfig: 'appConfig',
    }),
  },
  data() {
    return {
      active: '/community',
      homeIcon: require('@/assets/png/homeIcon.png'),
      homeIconActive: require('@/assets/png/homeIconActive.png'),
      community: require('@/assets/png/moviesIcon.png'),
      communityActive: require('@/assets/png/moviesIconActive.png'),
      gameIcon: require('@/assets/png/game.png'),
      gameActive: require('@/assets/png/gameActive.png'),
      videoIcon: require('@/assets/png/douying.png'),
      videoIconActive: require('@/assets/png/douyingActive.png'),
      msgIcon: require('@/assets/png/msgIcon.png'),
      msgIconActive: require('@/assets/png/msgIconActive.png'),
      // ypIcon: require("@/assets/png/ypIcon.png"),
      // ypIconActive: require("@/assets/png/ypIconActive.png"),
      mineIcon: require('@/assets/png/mineIcon.png'),
      mineIconActive: require('@/assets/png/mineIconActive.png'),
    };
  },
  mounted() {
    this.refreshRoute(this.$route.path);
  },
  created() {
    this.refreshRoute(this.$route.path);
  },
  methods: {
    // 刷新路由
    refreshRoute(path) {
      if (path.match(/\/(\S*)\//)) {
        this.active = firstUpperCase(path.match(/\/(\S*)\//)[1]);
      } else {
        this.active = firstUpperCase(this.$route.name);
      }
    },
    onMovies() {
      let path = getSessionItem('moviesPath') || '/movies';
      this.$router.replace(path);
    },
    onCommunity() {
      let path = getSessionItem('communityPath') || '/community';
      this.$router.replace(path);
    },
    refreshPage() {
      this.$store.commit('commnity/CHANGE_REFRESHSTA', true);
    },
    async noRedMessage() {
      let res = await this.$Api(noRedMessage);
      if (res && res.code == 200) {
        this.$store.commit('user/SET_UNREADMSG', res.data);
      }
    },
  },
  watch: {
    '$route.path'(newVal, oldVal) {
      this.refreshRoute(newVal);
    },
  },
};
</script>
<style scoped lang="scss">
.tabbar-warp {
  width: 100%;
  height: 60px;
  background: $bg;
  border-top: 1px solid #e8e8e8;
}
/deep/ .van-info {
  top: -4px;
  border: none;
}
/deep/ .van-tabbar-item__icon {
  font-size: 26px;
}
/deep/ .van-tabbar-item__icon img {
  height: 24px;
}
/deep/ .van-tabbar-item__text {
  padding-top: 2x;
  font-size: 12px;
  color: #868b9b;
}
/deep/ .van-tabbar-item {
  padding-bottom: 8px;
}
.van-tabbar-item--active {
  background: $bg;
}

[class*='van-hairline']::after {
  border: none;
}
</style>
